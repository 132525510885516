.bwd-package-detail-card {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 310px;
    background-color: #ffffff;
    height: 100%;
  }

  &__swiper {
    flex-shrink: 0;
    width: 100%;
    height: 164px;

    &.swiper {
      .swiper-pagination {
        &.swiper-pagination-bullets {
          bottom: 15px;

          .swiper-pagination-bullet {
            background-color: #fff;
            height: 6px;
            border-radius: 100px;
            margin: 0 4px;
            border: initial;
            &-active {
              width: 20px;
            }
          }
        }
      }
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 16px 8px;
    height: 100%;

    &__title {
      @include ellipsis-multiline(24px, 34px, 1);
    }

    &__resort {
      &-location {
        position: relative !important;
        margin-top: 8px;
        width: fit-content;
        font-weight: 400 !important;
      }

      &-locationText {
        @include ellipsis-multiline(16px, 24px, 1);
        text-decoration: underline;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      margin: 16px 0;
      height: 56px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $barcelo-black;
        border-radius: 10px;
        cursor: pointer;
      }

      &-tag {
        padding: 4px 8px;
        height: fit-content;
        background-color: #eaeef1;
        border-radius: 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }

    &__hotels {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      &-size {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #888c8f;
        span {
          color: #606669;
          line-height: 16px;
          font-weight: 300;
          padding: 2px 10px;
          margin-left: 3px;
        }
      }
      &-link {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #274b62;
        text-decoration: underline;
      }
      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 8px;
      }
    }

    &__hotel {
      max-width: 31.5%;
      &-image,
      .cmp-image {
        margin-bottom: 9px;
        border-radius: 15%;
        height: 72px;
      }
      &-name {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        word-break: normal;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }
    }

    &__price {
      &-content {
        margin-top: 8px;
        font-weight: 600;
        color: $barcelo-black !important;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: end;
      }
      .c-price-from {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #888c8f;
      }

      &-number {
        font-size: 28px !important;
        line-height: 36px !important;
        color: $barcelo-black !important;
      }

      &-content {
        .c-price {
          min-width: 0px !important;
        }
      }

      &-currency {
        &::after {
          font-size: 20px !important;
          line-height: 34px !important;
        }
      }
    }

    &__contact {
      margin-top: 16px;
      justify-content: center;
    }
  }
}

.bwd-package-comparator {
  .c-hotels-comparator__body {
    .result__list-item {
      display: flex;
      .result__comparator-bar ~ div {
        display: flex;
      }
    }
  }
}
